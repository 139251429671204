import { Button, Form, Input, message } from 'antd'
import React, { useState } from 'react'
import { authApi } from '../services/service';

export default function Password() {
    const [messageApi, contextHolder] = message.useMessage();

    const success = (text) => {
        messageApi.open({
            type: 'success',
            content: text,
        });
    };
    const error = (text) => {
        messageApi.open({
            type: 'error',
            content: text,
        });
    };


    const [password, setPassword] = useState({});
    const [loading, setLoading] = useState(false);

    const updatePassword = () => {
        setLoading(true)
        authApi.paswordUpdate(password).then((result) => {
            success("Güncellendi")
            setPassword({})
            setLoading(false)
        }).catch((err) => {
            error(err.message)
            setLoading(false)
        });
    }

    return (
        <div>
            {contextHolder}
            <Form layout='vertical'>
                <Form.Item label="Eski Şifre">
                    <Input
                        type='password'
                        value={password.oldPassword}
                        onChange={(e) => setPassword(password => ({ ...password, ["oldPassword"]: e.target.value }))}
                        placeholder='Eski Şifre'
                    />
                </Form.Item>
                <Form.Item label="Yeni Şifre">
                    <Input
                        type='password'
                        value={password.newPassword}
                        onChange={(e) => setPassword(password => ({ ...password, ["newPassword"]: e.target.value }))}
                        placeholder='Yeni Şifre'
                    />
                </Form.Item>
                <Form.Item label="Yeni Şifre (Tekrar)">
                    <Input
                        type='password'
                        value={password.newPasswordConfirm}
                        onChange={(e) => setPassword(password => ({ ...password, ["newPasswordConfirm"]: e.target.value }))}
                        placeholder='Yeni Şifre (Tekrar)'
                    />
                </Form.Item>
                <Form.Item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button loading={loading} onClick={updatePassword} type='primary' style={{ margin: "auto" }}>Güncelle</Button>
                </Form.Item>
            </Form>
        </div>
    )
}
