import { Button, Card, Form, Input, message } from 'antd'
import React, { useState } from 'react'
import { authApi } from '../../services/service';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo/logo.png'
export default function Login() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({});
    const [messageApi, contextHolder] = message.useMessage();

    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'You are logged in',
        });
    };
    const error = () => {
        messageApi.open({
            type: 'error',
            content: 'Email or password is incorrect',
        });
    };

    const onFinish = (values) => {
        setLoading(true)
        authApi.login(values).then(async (result) => {
            localStorage.setItem('authToken', result.data.token)
            await authApi.user().then(async (user) => {
                await localStorage.setItem('user', JSON.stringify(user.data));
                setLoading(false)
                success()
                navigate("/");
            }).catch((err) => {
                setLoading(false)
                error()
            });
        }).catch((err) => {
            setLoading(false)
            error()
        });
    };
    const onFinishFailed = (errorInfo) => {
    };


    return (
        <div style={{ backgroundColor: '#f6f6f6', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {contextHolder}
            <Card style={{ backgroundColor: '#fff', width: 'fit-content' }}>
                <div style={{ width: '250px', height: '190px',marginBottom:'50px' }}>
                    <img src={logo} style={{ width: '100%', height: '100%' }} />
                </div>
                <Form
                    layout='vertical'
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="E-mail"
                        name="email"
                        rules={[{ required: true, message: 'Lütfen e-mail girin!' }]}
                    >
                        <Input placeholder='E-mail' />
                    </Form.Item>
                    <Form.Item
                        label="Şifre"
                        name="password"
                        rules={[{ required: true, message: 'Lütfen şifre girin!' }]}
                    >
                        <Input type='password' placeholder='Password' />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center', marginTop: '20px' }}>
                        <Button loading={loading} htmlType="submit" type='primary'>Giriş Yap</Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}
