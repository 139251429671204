import { Modal, Tabs } from 'antd'
import React from 'react'
import Password from './Password';
import Email from './Email';

export default function AccountModal({ isModalOpen, onCancel, email, setEmail }) {

    const items = [
        {
            key: '1',
            label: `E-mail`,
            children: <Email email={email} setEmail={setEmail} />,
        },
        {
            key: '2',
            label: `Password`,
            children: <Password />,
        }
    ];

    return (
        <Modal title="Hesap Bilgilerini Güncelle" footer={null} open={isModalOpen} onCancel={onCancel}>
            <Tabs tabPosition='top' centered={true} defaultActiveKey="1" items={items} />
        </Modal>
    )
}
