import Dashboard from "../pages/dashboard/Dashboard";
import Login from "../pages/login/Login";
import Product from "../pages/product/Product";

 const routes = [
    {
        key: "login",
        path: "/login",
        exact: true,
        element: <Login />
    },
    {
        key: "dashboard",
        path: "/",
        exact: true,
        element: <Dashboard />
    },
    {
        key: "product",
        path: "/product/:id",
        exact: true,
        element: <Product />
    },
]

export default routes