import axios from 'axios';
import { notification } from 'antd';

export const client = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'Authorization': null
    },
});

client.interceptors.request.use(
    (config) => {
        const AUTH_TOKEN = localStorage.getItem('authToken');
        if (AUTH_TOKEN) {
            config.headers.Authorization = `Bearer ${AUTH_TOKEN}`;
        } else {
            delete config.headers.Authorization;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error.response.data)
    },
);

client.interceptors.response.use(

    (response) => response,
    (error) => {


        if (!error.response) {
            return Promise.reject(error);
        }

        if (error.response.status === 401 && window.location.pathname !== '/login') {
            localStorage.removeItem('auth');
            localStorage.removeItem('authToken');
            window.location.href = '/';
        }

        if (error.response.status === 400) {
            if (
                error.response.data &&
                error.response.data.Errors &&
                Array.isArray(error.response.data.Errors)
            ) {
                notification.error({
                    message: 'Validation Error',
                    description: error.response.data.Errors[0],
                });
            }
        }
        if (error.response.status === 403) {

            console.log("error", "Error", "You Dont Have Enough Permissions")
        }

        if (error.response) {
            return Promise.reject(error.response.data);
        }

        return Promise.reject(error);
    }
);