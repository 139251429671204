import { DeleteOutlined, InboxOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Form, Image, Input, Modal, Popconfirm, Row } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import React from 'react'

export default function EditButton({ detail, isModalUpdateOpen, handleUpdate, confirmDeleteImage, handleUpdateCancel, propsUpdate, setDetail, editForm, setEditForm }) {
    const fileUrl = 'https://modelis.peakersoft.net/assets/images/products'

    const confirm = (file) => {
        console.log("resmi db den sil");
    }

    return (
        <div>
            <Modal title={`${detail?.name} Güncelle`} cancelText="Vazgeç" okText="Güncelle" open={isModalUpdateOpen} onOk={handleUpdate} onCancel={handleUpdateCancel}>
                <div>
                    <Form layout='vertical'>
                        <Form.Item>
                            <Dragger {...propsUpdate}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Tıklayarak ürün resimlerini yükleyebilirsiniz</p>
                            </Dragger>
                        </Form.Item>
                        <div>
                            {
                                editForm?.image?.length > 0 ?
                                    <Row style={{ gap: '10px' }}>
                                        {
                                            editForm?.image?.map(e => (
                                                <Col key={e.uid}>
                                                    <div style={{ display: 'grid' }}>
                                                        <Image
                                                            width={100}
                                                            height={100}
                                                            src={`${fileUrl}/${e.image}`}
                                                        />
                                                        <Popconfirm
                                                            title="Resmi sil"
                                                            description="Resmi silmek istediğinizden emin misiniz?"
                                                            onConfirm={() => confirmDeleteImage(e.id, detail.id)}
                                                            onCancel={() => false}
                                                            okText="Evet, Sil"
                                                            cancelText="Vazgeç"
                                                        >
                                                            <Button style={{ marginTop: '10px' }}><DeleteOutlined /></Button>
                                                        </Popconfirm>
                                                    </div>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                    : <></>
                            }
                        </div>
                        <Divider />
                        <Form.Item
                            label="Ürün Adı">
                            <Input value={editForm?.name} onChange={(e) => setEditForm(editForm => ({ ...editForm, ['name']: e.target.value }))} placeholder='Ürün Adı' />
                        </Form.Item>
                        <Form.Item
                            label="Ürün Kodu">
                            <Input value={editForm?.code} onChange={(e) => setEditForm(editForm => ({ ...editForm, ['code']: e.target.value }))} placeholder='Ürün Kodu' />
                        </Form.Item>
                        <Form.Item
                            label="Ürün Açıklaması" >
                            <Input value={editForm?.desc} onChange={(e) => setEditForm(editForm => ({ ...editForm, ['desc']: e.target.value }))} placeholder='Ürün Açıklaması' />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}
