import React, { useEffect, useState } from 'react'
import { Button, Divider, Drawer, Form, Image, Input, Modal, Popconfirm, Space, Spin, Table, message } from 'antd'
import { DeleteOutlined, EditOutlined, EyeOutlined, InboxOutlined, PlusOutlined } from '@ant-design/icons'
import DefaultLayout from '../../layout/DefaultLayout';
import { productApi } from '../../services/service';
import { useNavigate } from 'react-router-dom';
import Dragger from 'antd/es/upload/Dragger';
import AddButton from '../../components/AddButton';
import EditButton from '../../components/EditButton';

export default function Dashboard() {
    // const fileUrl = 'http://127.0.0.1:8001/assets/images/products'
    const fileUrl = 'https://modelis.peakersoft.net/assets/images/products'
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [detail, setDetail] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false);
    const [form, setForm] = useState({ image: [] });
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [filter, setFilter] = useState('');
    const [editForm, setEditForm] = useState({});
    const [totalProduct, setTotalProduct] = useState(0);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setLoadingCreate(true)

        var formData = new FormData();
        if (form.name) {
            formData.append('name', form.name)
        }
        if (form.desc) {
            formData.append('desc', form.desc)
        }
        if (form.code) {
            formData.append('code', form.code)
        }

        if (form.image.length > 0) {
            form.image.forEach((file, index) => {
                formData.append('images[]', file);
            });
        } else {
            error("Please select image")
            setLoadingCreate(false)
            return
        }

        productApi.create(formData).then((result) => {
            setLoadingCreate(false)
            getData(1, 5, true)
            setForm({ image: [] })
            setIsModalOpen(false);
            success("Success")
        }).catch((err) => {
            setLoadingCreate(false)
            error("Error")
        });
    };

    const handleForm = (name, value) => {
        setForm(form => ({ ...form, [name]: value }))
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showUpdateModal = (id) => {
        productApi.detail(id).then((result) => {
            console.log(result.data);
            setDetail(result.data)
            setEditForm(result.data)
            setIsModalUpdateOpen(true);
        }).catch((err) => {

        });
    };

    const handleUpdate = () => {
        setLoadingBtn(true)
        productApi.update(editForm.id, editForm).then((result) => {
            setLoadingBtn(false)
            console.log(result.data);
            setIsModalUpdateOpen(false)
            const nextPage = 1;
            setPage(nextPage)
            getData(nextPage, perPage, true);
        }).catch((err) => {
            setLoadingBtn(false)
            error("Error")
        });
    };

    const handleUpdateCancel = () => {
        setDetail({})
        setIsModalUpdateOpen(false);
    };

    const infinityScroll = async () => {
        const nextPage = page + 1;
        setPage(nextPage)
        await getData(nextPage, perPage, false);
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY; // Sayfanın dikey kaydırma değeri
            const clientHeight = document.documentElement.clientHeight; // Tarayıcının görünen penceresinin yüksekliği
            const scrollHeight = document.documentElement.scrollHeight; // Sayfanın toplam yüksekliği

            // İsteğe bağlı olarak sayfa sonuna gelmeden önce bir "yükleme eşiği" belirleyebilirsiniz
            // Örneğin, son 20px içindeyken yükleme yapmak için bu değeri 20 yapabilirsiniz
            const loadThreshold = 0;

            if (scrollY + clientHeight >= scrollHeight - loadThreshold) {
                infinityScroll();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [page]);

    const success = (text) => {
        messageApi.open({
            type: 'success',
            content: text,
        });
    };
    const error = (text) => {
        messageApi.open({
            type: 'error',
            content: text,
        });
    };

    const getData = (page, perPage, isFirst) => {
        setLoading(true)
        productApi.all(page, perPage, filter).then((result) => {
            if (isFirst) {
                setData(result.data.data);
            } else {
                setData(data => [...data, ...result.data.data]);
            }
            setTotalProduct(result.data.total)
            setLoading(false)
        }).catch((err) => {
            setLoading(false)

        });
    }

    useEffect(() => {
        getData(page, perPage, true)
    }, []);

    const showDrawer = (data) => {
        setDetail(data);
        setOpen(true);
    };
    const onClose = () => {
        setDetail({});
        setOpen(false);
    };

    const confirm = (id) => {
        setLoadingBtn(true)
        productApi.remove(id).then((result) => {
            setLoadingBtn(false)
            getData(page, perPage, true)
            success("Deleted")
        }).catch((err) => {
            setLoadingBtn(false)
            error("Error")
        });
    };

    const confirmDeleteImage = (id, detailId) => {
        productApi.removeImage(id).then((result) => {
            showUpdateModal(detailId)
            success("Success")
        }).catch((err) => {
            error("Error")
        });
    }

    const cancel = (e) => {

    };

    const props = {
        name: 'file',
        multiple: true,
        showUploadList: false,
        customRequest: ({ file }) => {
            setForm(form => ({ ...form, ["image"]: [...form["image"], file] }))
            return true
        },
        onRemove: (file) => {
            setForm((prevForm) => ({
                ...prevForm,
                image: prevForm.image.filter((item) => item.uid !== file.uid),
            }));
        },
    };

    const propsUpdate = {
        name: 'file',
        multiple: true,
        showUploadList: false,
        customRequest: ({ file }) => {
            var formData = new FormData();
            formData.append('images[]', file);
            productApi.upload(detail.id, formData).then((result) => {
                success("Success")
                showUpdateModal(detail.id)
            }).catch((err) => {
                error("Error")
            });
        },
    };

    const columns = [
        {
            title: 'Ürün Resmi',
            dataIndex: 'image',
            key: 'image',
            render: (text) => (
                <Image
                    width={100}
                    height={150}
                    src={`${fileUrl}/${text[0]?.image}`} />
            )
        },
        {
            title: 'Ürün Adı',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <span>{text}</span>
        },
        {
            title: 'Ürün Kodu',
            dataIndex: 'code',
            key: 'code',
            render: (text) => <span>{text}</span>
        },
        {
            title: 'Ürün Açıklaması',
            dataIndex: 'desc',
            key: 'desc',
            render: (text) => <span>{text.length > 30 ? text?.substr(0,30)+'...' : text}</span>
        },
        {
            title: 'İşlemler',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Button onClick={() => showDrawer(_)}><EyeOutlined /></Button>
                        <Button onClick={() => showUpdateModal(_.id)}><EditOutlined /></Button>
                        <Popconfirm
                            title="Ürün Sil"
                            description="Ürünü silmek istediğinize emin misiniz?"
                            onConfirm={() => confirm(_.id)}
                            onCancel={cancel}
                            okText="Evet, Sil!"
                            cancelText="Vazgeç"
                            okButtonProps={{ loading: loadingBtn }}
                        >
                            <Button><DeleteOutlined /></Button>
                        </Popconfirm>
                    </div>
                </Space>
            ),
        },
    ]

    useEffect(() => {
        getData(page, perPage, true)
    }, [filter]);

    return (
        <DefaultLayout>
            {contextHolder}
            <div style={{
                marginTop: 30,
                display: 'flex',
                justifyContent: 'end'
            }}>
                <AddButton
                    showModal={showModal}
                    isModalOpen={isModalOpen}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    props={props}
                    form={form}
                    setForm={setForm}
                    handleForm={handleForm}
                    loadingCreate={loadingCreate}
                />
            </div>
            <div
                style={{
                    marginTop: 30,
                }}
            >
                <span style={{ fontSize: '16px' }}>Toplam <span style={{ fontWeight: 700 }}>{totalProduct}</span> Ürün</span>
            </div>
            <div
                style={{
                    marginTop: 15,
                    padding: 24,
                    minHeight: '100vh',
                    background: '#ffffff',
                }}
            >
                <div>
                    <div>
                        <h3>Ürünler Tablosu</h3>
                    </div>
                    <div style={{ margin: '10px 0' }}>
                        <Input
                            value={filter}
                            onChange={e => setFilter(e.target.value)}
                            placeholder='Ürün Ara' />
                    </div>
                    <div>
                        {
                            loading ? <Spin />
                                :
                                <Table width={100} pagination={false} columns={columns} dataSource={data} />
                        }
                    </div>
                    <div>
                        <Drawer title={detail?.name} placement="right" onClose={onClose} open={open}>
                            <div>
                                <div style={{ textAlign: 'center' }}>
                                    <Image.PreviewGroup
                                        items={detail?.image?.map((image) => `${fileUrl}/${image?.image}`)}
                                    >
                                        <Image
                                            width={200}
                                            src={detail.image ? `${fileUrl}/${detail?.image[0]?.image}` : ''}
                                        />
                                    </Image.PreviewGroup>
                                </div>
                                <Divider />
                                <div>
                                    <p>Ürün Adı: <b>{detail?.name}</b></p>
                                </div>
                                <Divider />
                                <div>
                                    <p>Ürün Kodu: <b>{detail?.code}</b></p>
                                </div>
                                <Divider />
                                <div>
                                    <p>Ürün Açıklaması: <b>{detail?.desc}</b></p>
                                </div>
                            </div>
                        </Drawer>
                    </div>
                    <EditButton
                        detail={detail}
                        setDetail={setDetail}
                        editForm={editForm}
                        setEditForm={setEditForm}
                        isModalUpdateOpen={isModalUpdateOpen}
                        handleUpdate={handleUpdate}
                        handleUpdateCancel={handleUpdateCancel}
                        propsUpdate={propsUpdate}
                        confirmDeleteImage={confirmDeleteImage}
                    />
                </div>
            </div>
        </DefaultLayout>
    )
}
