import { DeleteOutlined, InboxOutlined, PlusOutlined } from '@ant-design/icons'
import { Badge, Button, Col, Divider, Form, Image, Input, Modal, Popconfirm, Row } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import React from 'react'

export default function AddButton({ showModal, isModalOpen, handleOk, handleCancel, props, handleForm, form, setForm,loadingCreate }) {
    const confirm = (file) => {
        setForm((prevForm) => ({
            ...prevForm,
            image: prevForm.image.filter((item) => item.uid !== file.uid),
        }));
    }
    return (
        <>
            <Button onClick={showModal} type='primary' icon={<PlusOutlined />}>Ekle</Button>
            <Modal confirmLoading={loadingCreate} title="Ürün Ekle" cancelText="Vazgeç" okText="Ekle" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div>
                    <Form layout='vertical'>
                        <Form.Item>
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Tıklayarak ürün resimlerini yükleyebilirsiniz</p>
                            </Dragger>
                        </Form.Item>
                        <div>
                            {
                                form.image.length > 0 ?
                                    <Row style={{ gap: '10px' }}>
                                        {
                                            form?.image?.map(e => (
                                                <Col key={e.uid}>
                                                    <div style={{ display: 'grid' }}>
                                                        <Image
                                                            width={100}
                                                            height={100}
                                                            src={URL.createObjectURL(e)} />
                                                        <Popconfirm
                                                            title="Resmi Sil"
                                                            description="Resmi silmek istediğinize emin misiniz?"
                                                            onConfirm={() => confirm(e)}
                                                            onCancel={() => false}
                                                            okText="Evet, Sil!"
                                                            cancelText="Hayır"
                                                        >
                                                            <Button style={{ marginTop: '10px' }}><DeleteOutlined /></Button>
                                                        </Popconfirm>
                                                    </div>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                    : <></>
                            }
                        </div>
                        <Divider />
                        <Form.Item
                            label="Ürün Adı"
                        >
                            <Input value={form.name} onChange={(e) => handleForm("name", e.target.value)} placeholder='Ürün Adı' />
                        </Form.Item>
                        <Form.Item
                            label="Ürün Kodu"
                        >
                            <Input value={form.code} onChange={(e) => handleForm("code", e.target.value)} placeholder='Ürün Kodu' />
                        </Form.Item>
                        <Form.Item
                            label="Ürün Açıklaması"
                        >
                            <Input value={form.desc} onChange={(e) => handleForm("desc", e.target.value)} placeholder='Ürün Açıklaması' />
                        </Form.Item>
                    </Form>
                </div >
            </Modal >
        </>
    )
}
