import React from 'react';
import Dashboard from './pages/dashboard/Dashboard';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import routes from './routes/routes'

const App = () => {
  return (
    <Router>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={route.element}
            exact={route.exact}
          />
        ))}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};
export default App;