import { Button, Form, Input, message } from 'antd'
import React, { useState } from 'react'
import { authApi } from '../services/service';

export default function Email({ email, setEmail }) {
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const success = (text) => {
        messageApi.open({
            type: 'success',
            content: text,
        });
    };
    const error = (text) => {
        messageApi.open({
            type: 'error',
            content: text,
        });
    };


    const updateEmail = () => {
        setLoading(true)
        authApi.userUpdate({ email }).then((result) => {
            success('Güncellendi')
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            error('Lütfen Geçerli bir email girin!')
        });
    }

    return (
        <div>
            {contextHolder}
            <Form layout='vertical'>
                <Form.Item label="E-mail">
                    <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder='E-mail' />
                </Form.Item>
                <Form.Item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button onClick={updateEmail} loading={loading} type='primary' style={{ margin: "auto" }}>Güncelle</Button>
                </Form.Item>
            </Form>
        </div>
    )
}
