import React, { useState } from 'react'
import { Layout, Menu, Modal, message } from 'antd';
import { Navigate, useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo/logo.png'
import { LogoutOutlined } from '@ant-design/icons';
import AccountModal from '../components/AccountModal';
import { authApi } from '../services/service';

export default function DefaultLayout({ children }) {
    const { Header, Content, Footer } = Layout;
    const navigate = useNavigate()
    const token = localStorage.getItem("authToken") || '';
    const [messageApi, contextHolder] = message.useMessage();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [email, setEmail] = useState('');


    const showModal = () => {
        authApi.user().then((result) => {
            setEmail(result.data.email);
            setIsModalOpen(true);
        }).catch((err) => {

        });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'You are logout',
        });
    };

    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('authToken');
        success()
        navigate('login')
    };


    return (
        <>
            {
                token == '' ?
                    <Navigate to={'/login'} replace={true} />
                    :
                    <Layout >
                        <Header
                            style={{
                                backgroundColor: '#fff',
                                position: 'sticky',
                                height: 'auto',
                                top: 0,
                                zIndex: 1,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <div className="demo-logo" style={{ width: '200px', height: '150px', padding: '10px' }} >
                                <img src={logo} style={{ width: '100%', height: '100%' }} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ width: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div className=''>
                                        <a onClick={showModal}>Hesap Ayarları</a>
                                    </div>
                                </div>
                                <div style={{ width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div className=''>
                                        <a onClick={logout}>Çıkış Yap</a>
                                    </div>
                                </div>
                            </div>

                        </Header>
                        <AccountModal
                            isModalOpen={isModalOpen}
                            onCancel={handleCancel}
                            email={email}
                            setEmail={setEmail}
                        />
                        <Content
                            className="site-layout"
                            style={{
                                padding: '0 50px',
                            }}
                        >
                            {children}
                        </Content>
                        <Footer
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            Created By                        </Footer>
                    </Layout>
            }
        </>
    )
}
