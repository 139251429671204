/*eslint-disable */
import { URL } from './url';
import { client } from './client.js';
import axios from 'axios';

const AuthApi = () => ({
    login(body) {
        return client.post(URL.login, body);
    },
    user() {
        return client.get(URL.user);
    },
    userUpdate(body) {
        return client.post(URL.userUpdate, body);
    },
    paswordUpdate(body) {
        return client.post(URL.paswordUpdate, body);
    },
});

const ProductApi = () => ({
    all(page, per_page, filter) {
        var url = `${URL.product}/all?page=${page}&per_page=${per_page}`
        if (filter) {
            url += `&filter=${filter}`
        }
        return client.get(url);
    },
    detail(id) {
        return client.get(`${URL.product}/detail/${id}`);
    },
    create(body) {
        return client.post(`${URL.product}`, body, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
    update(id, body) {
        return client.post(`${URL.product}/${id}`, body);
    },
    upload(id, body) {
        return client.post(`${URL.product}/upload/${id}`, body, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
    removeImage(id) {
        return client.delete(`${URL.product}/remove-image/${id}`);
    },
    remove(id) {
        return client.delete(`${URL.product}/${id}`);
    }
});

export const authApi = AuthApi();
export const productApi = ProductApi();
